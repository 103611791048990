import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocuChainList from "./docu-chain-list";
import GenericStep from "./generic-step";
import { Drawer, ToggleButton, ToggleButtonGroup } from "@mui/material";
import WorkspaceModalInfo from "../../../../stylized/workspaceModalInfo";
import { fetchDocumentChainList } from "../../../../../actions/DocumentChainActions";
import { setUpdatedInstanceBranch } from "../../../../../actions/Rippleaction";
import { openInstance } from "../../../../../actions/DashboardActions";
import "./style.css";
import ShareFlow from "../WorkspaceContainer/AddFolderModal/ShareFlow";
import AddGeneric from "./generic-step/AddGeneric";
import http from "../../../../../bae-client/HTTP";
import InstanceDropdown from "./instance-dropdown";
import InstanceStep from "./instance-step";
import InstanceContainer from "./docu-chain-list/empty-container/InstanceContainer";
import AddStep from "./modals/AddStep";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../../../stylized/Loader";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import AddInstance from "./modals/AddInstance";
import MergeInstanceSteps from "./modals/MergeInstanceSteps";
import ShareGenericSteps from "./ShareGenericSteps";
import AlertDialog from "../../../../stylized/AlertDialog";
import DialogView from "../../../../stylized/DialogView";
import AddStepStepper from "./modals/AddStepStepper";
import BranchView from "./branch-view";
import * as Mixpanel from "../../../../../mix-panel/Mixpanel";
import { fetchFilterData } from "../../../../../actions/DashboardActions";
import RecentActivityManager from "../../../../../bae-client/RecentActivityManager";
import Members from "../WorkspaceContainer/members-modal/Members";
import { Switch, styled, alpha } from "@mui/material";
import { validateFileType } from "../Workspace/constant";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FlowRule from "./modals/FlowRule";
import { setSnackbarAction } from "../../../../../services/services/app";
import { ButtonComponent } from "../../../../button/button";
import ManageAlerts from "./ManageAlerts";

export const DocChain = () => {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    var instanceTitle = useRef("");
    const [currentMembers, setCurrentMembers] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState([]);
    const [expectedDateMap, setexpectedDateMap] = useState(null);
    const [starredInstances, setStarredInstances] = useState([]);
    const [displayViewData, setDisplayViewData] = useState([]);
    const [recentlyCreatedInstance, setRecentlyCreatedInstance] = useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [shareFile, setShareFile] = useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [branchsId, setBranchId] = React.useState(0);
    const [branchsName, setBranchsName] = React.useState("");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [documentChainList, setDocumentChainList] = useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [selectedGenericInstances, setSelectedGenericInstances] = useState(
        []
    );
    const [showMembersModal, setShowMembersModal] = useState(false);
    const [membersList, setMembersList] = useState([]);
    const [selectedStepId, setSelectedStepId] = React.useState([]);
    const [selectedGenericSteps, setSelectedGenericSteps] = useState([]);
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });

    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const closeShareClick = () => {
        setShareFile(false);
    };

    const [selectedInstanceSteps, setSelectedInstanceSteps] = useState([]);
    const [selectedMergeBlocks, setSelectedMergeBlocks] = useState([]);
    const [branchViewData, setBranchViewData] = useState(null);
    const [flowRuleData, setFlowRuleData] = useState(null);
    const [showDocumentChain, setShowDocumentChain] = useState(false);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [showAddStepModal, setShowAddStepModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [editGeneric, setEditGeneric] = useState(false);
    const [parentStepId, setParentStepId] = React.useState(0);
    const [showAddInstanceModal, setShowAddInstanceModal] = useState(false);
    const [showMergeInstanceStepsModal, setShowMergeInstanceStepsModal] =
        useState(false);
    const [showGenericStepsModal, setShowGenericStepsModal] = useState(false);
    const [showFlowModal, setShowFlowModal] = useState(false);
    const [showFlowRuleModal, setShowFlowRuleModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayShow, setDisplayShow] = React.useState(false);
    const [openInstanceStepModal, setOpenInstanceStepModal] = useState(false);
    const [isBranchView, setIsBranchView] = useState(false);
    const [activeTab, setActiveTab] = useState("Instance");
    const orderByColumn = React.useRef("");
    const open = Boolean(anchorEl);
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };
    const [openDrawer, closeDrawer] = useState(false);

    const dispatch = useDispatch();
    const instanceBranch = useSelector((state) => state.instanceBranch);
    const DocumentChainReducer = useSelector(
        (state) => state.DocumentChainReducer
    );
    const openInstanceData = useSelector((state) => state.InstanceData);

    const handleChange = (event) => {
        setIsBranchView(event.target.checked);
    };
    useEffect(() => {
        let instanceMap = new Map();
        const getActualDate = (prevStep, duration) => {
            if (!prevStep) return "";
            if (instanceMap.get(prevStep.expectedDurationFrom)) {
                instanceMap.set(prevStep.instanceStepId, {
                    expectedDate:
                        new Date(
                            instanceMap.get(
                                prevStep?.expectedDurationFrom
                            )?.expectedDate
                        ).getTime() +
                        duration * 60000,
                });
                return (
                    new Date(
                        instanceMap.get(
                            prevStep.expectedDurationFrom
                        ).expectedDate
                    ).getTime() +
                    duration * 60000
                );
            }
        };
        const getExpectedDateForStep = (instanceStep, index) => {
            if (!instanceStep) return "";
            if (
                instanceStep.expectedDate ||
                instanceStep.instanceBlockDocumentName
            )
                instanceMap.set(instanceStep.instanceStepId, {
                    expectedDate:
                        instanceStep?.expectedDate || instanceStep.actualDate,
                });
            if (instanceStep.actualDate) return;
            if (!instanceStep.expectedDate) {
                getActualDate(instanceStep, instanceStep.expectedDuration);
            }
        };
        selectedInstanceSteps.map((item, index) => {
            getExpectedDateForStep(item, index);
        });
        setexpectedDateMap(instanceMap);
    }, [selectedInstanceSteps]);
    useEffect(() => {
        Mixpanel.callPageViewEvent("Flow");
        dispatch(fetchDocumentChainList());
    }, []);
    useEffect(() => {
        if (instanceBranch === true) fetchInstanceList(selectedInstance);
    }, [instanceBranch]);
    useEffect(() => {
        if (openInstanceData && DocumentChainReducer) {
            setShowDocumentChain(true);
            const genericArray = DocumentChainReducer.documentChainList.filter(
                (list) => list.genericId === openInstanceData.genericId
            );
            let displayName = genericArray.length
                ? genericArray[0].genericDisplayName
                : "";
            let genericName = genericArray.length
                ? genericArray[0].genericName
                : "";
            setSelectedRow({
                genericId: openInstanceData.genericId,
                docuChain: genericName, //openInstanceData.genericName,
                genericDisplayName: displayName,
            });
        }
    }, [openInstanceData, DocumentChainReducer]);
    useEffect(() => {
        if (openInstanceData && selectedGenericInstances.length) {
            const instanceId = openInstanceData.instanceIds[0];
            const newInstance = selectedGenericInstances.filter(
                (instance) => instance.instanceId === instanceId
            );
            setSelectedInstance(newInstance[0]);
            dispatch(openInstance(null));
        }
    }, [openInstanceData, selectedGenericInstances]);

    useEffect(() => {
        if (DocumentChainReducer && DocumentChainReducer.documentChainList) {
            const sortedData = DocumentChainReducer.documentChainList.sort(
                (a, b) => {
                    if (b.updatedOn.toUpperCase() < a.updatedOn.toUpperCase()) {
                        return -1;
                    }
                    if (b.updatedOn.toUpperCase() > a.updatedOn.toUpperCase()) {
                        return 1;
                    }
                    return 0;
                }
            );
            setDocumentChainList(sortedData);
        }
    }, [DocumentChainReducer]);
    useEffect(() => {
        if (selectedRow && showDocumentChain) {
            setSelectedInstanceSteps([]);
            setBranchViewData(null);
            setSelectedInstance(null);
            setSelectedGenericInstances([]);
            setSelectedGenericSteps([]);
            setShowLoader(true);
            http.sendRestRequest(
                `documentChain/generic/stepList?genericId=${selectedRow.genericId}`
            )
                .then((result) => {
                    result.sort((a, b) => {
                        return a.genericOrderId - b.genericOrderId;
                    });

                    setSelectedGenericSteps(result);
                    if (result && result.length === 0) setEditGeneric(true);
                    setShowLoader(false);
                })
                .catch((error) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        }
    }, [selectedRow, showDocumentChain]);
    useEffect(() => {
        if (selectedInstance) {
            if (recentlyCreatedInstance !== "") {
                let recentActivity = new RecentActivityManager();
                let dataObj = {
                    objectType: "INSTANCE",
                    objectId: selectedInstance.instanceId,
                    description: `Created ${selectedInstance.instanceName}`,
                    path: `${selectedRow.genericId}`,
                };
                recentActivity
                    .createRecentActivity(dataObj)
                    .then((recentRes) => {
                        console.log(recentRes);
                    })
                    .catch((err) => {
                        console.log(err.error);
                    });
                setRecentlyCreatedInstance("");
            }
            let url = "userInteractions/flow/recentlyViewed";
            const descObj = {
                objectId: selectedInstance.instanceId,
                objectName: selectedInstance.instanceName,
                objectType: "INSTANCE",
            };
            let createdViewed = {
                genericId: selectedRow.genericId,
                description: JSON.stringify(descObj),
            };
            http.sendRestRequest(url, "POST", createdViewed)
                .then((resp) => {
                    console.log(resp);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [selectedInstance]);

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        closeDrawer(open);
    };

    const getPossibleTargetSteps = (branchViewData) => {
        const instanceSteps = branchViewData
            ? branchViewData.selectedInstanceSteps
            : [];
        let possibleTargetSteps = [];
        const object = {};
        let possibleSourceSteps = [];
        instanceSteps.forEach((step, index) => {
            if (object[step.branchName])
                object[step.branchName] = [...object[step.branchName], step];
            else object[step.branchName] = [step];
        });
        let index = 0;
        for (const branchName in object) {
            if (Object.hasOwnProperty.call(object, branchName)) {
                const branchSteps = object[branchName];
                const executedSteps = branchSteps.filter(
                    (step) => step.instanceStepName !== "Email"
                );
                possibleSourceSteps = [
                    ...possibleSourceSteps,
                    ...executedSteps,
                ];
                possibleTargetSteps[index] = branchSteps;
                index++;
            }
        }
        return {
            sources: possibleSourceSteps,
            target: possibleTargetSteps,
            generics: selectedGenericSteps,
        };
    };

    const addStar = (id, star) => {
        addStarFill(id, !star);
    };
    const addStarFill = (id, star) => {
        let url = `documentChain/instance/starred?genericId=${selectedRow.genericId}`;
        let data = {
            instanceId: id,
            isStarred: star,
        };
        http.sendRestRequest(url, "PUT", data)
            .then((resp) => {
                setShowLoader(false);

                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.genericId}`
                )
                    .then((instanceData) => {
                        setSelectedGenericInstances(instanceData);
                        const matchingData = instanceData.find(
                            (item) =>
                                item.instanceId === selectedInstance.instanceId
                        );

                        setSelectedInstance(matchingData);
                        setShowLoader(false);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            })
            .catch((err) => {
                showAlertView(
                    "Operation Failed",
                    err && err.error
                        ? err.error
                        : "Something went wrong, please try again!",
                    "alert_folder",
                    "btn_folder_close",
                    true
                );
                setShowLoader(false);
            });
    };

    const processExceptions = (exceptions) => {
        const result = Object.groupBy(
            exceptions,
            ({ instanceStepId }) => instanceStepId
        );
        const processedData = {};
        for (const key in result) {
            if (Object.hasOwnProperty.call(result, key)) {
                const element = result[key];
                processedData[key] = element
                    .map((i) => i.exceptionType)
                    .filter(
                        (i) =>
                            i.toLowerCase() !== "skipped" &&
                            i.toLowerCase() !== "delayed" &&
                            i.toLowerCase() !== "proactive alert"
                    );
            }
        }
        return processedData;
    };

    const fetchInstanceList = (selectedInstance) => {
        setBranchViewData(null);
        if (selectedInstance) {
            setShowLoader(true);
            http.sendRestRequest(
                `documentChain/instanceData?genericId=${selectedRow.genericId}&instanceId=${selectedInstance.instanceId}`
            )
                .then((instanceSteps) => {
                    setShowLoader(false);
                    const genericSteps = [...selectedGenericSteps].sort(
                        (a, b) => {
                            return (
                                parseInt(a.genericOrderId) -
                                parseInt(b.genericOrderId)
                            );
                        }
                    );
                    const mergeSteps = instanceSteps.filter(
                        (step) => step.blockType === "MERGE"
                    );
                    const executedSteps = instanceSteps
                        .filter((step) => step.actualDate)
                        .sort((a, b) => {
                            const date1 = new Date(a.actualDate);
                            const date2 = new Date(b.actualDate);
                            return date1 - date2;
                        });
                    const nonExecutedStepsWithExpectedDate = instanceSteps
                        .filter(
                            (step) =>
                                step.expectedDate &&
                                step.instanceBlockDocumentName === "" &&
                                step.durationStatus !== "SKIPPED"
                        )
                        .sort((a, b) => {
                            return (
                                parseInt(a.expectedDate) -
                                parseInt(b.expectedDate)
                            );
                        });
                    const skippedSteps = instanceSteps
                        .filter((step) => step.durationStatus === "SKIPPED")
                        .sort((a, b) => b.instanceStepId - a.instanceStepId);
                    skippedSteps.forEach((step) => {
                        if (step.parentInstanceStepId === "0") {
                            executedSteps.splice(0, 0, step);
                        } else {
                            const index = genericSteps
                                .map((step) => step.genericStepName)
                                .indexOf(step.instanceStepName);
                            const genStep = genericSteps[index + 1];
                            if (genStep) {
                                let sourceStep = executedSteps.filter(
                                    (executedStep) =>
                                        executedStep.instanceStepName ===
                                            genStep.genericStepName &&
                                        executedStep.branchId === step.branchId
                                );
                                if (sourceStep.length) {
                                    let index = executedSteps.findIndex(
                                        (executedStep) =>
                                            executedStep.instanceStepName ===
                                            sourceStep[0].instanceStepName
                                    );
                                    executedSteps.splice(index, 0, step);
                                }
                            } else executedSteps.push(step);
                        }
                    });
                    const nonExecutedSteps = instanceSteps.filter(
                        (step) =>
                            step.actualDate === "" &&
                            step.expectedDate === "" &&
                            step.durationStatus !== "SKIPPED"
                    );
                    let sortedNonExecutedSteps = [];
                    genericSteps.forEach((genStep) => {
                        let instanceSteps = nonExecutedSteps.filter(
                            (instanceStep) =>
                                genStep.genericStepName ===
                                instanceStep.instanceStepName
                        );
                        sortedNonExecutedSteps = [
                            ...sortedNonExecutedSteps,
                            ...instanceSteps,
                        ];
                    });
                    const allNonExecutedSteps = [
                        ...nonExecutedStepsWithExpectedDate,
                        ...sortedNonExecutedSteps,
                    ];
                    const genStepNames = genericSteps.map(
                        (step) => step.genericStepName
                    );
                    const customNonExecutedSteps = nonExecutedSteps.filter(
                        (step) =>
                            !genStepNames.includes(step.instanceStepName) &&
                            step.instanceUniqueStepIdentifier !== "SPLIT" &&
                            step.instanceUniqueStepIdentifier !== "MERGE"
                    );
                    customNonExecutedSteps.forEach((step) => {
                        const instanceStepIds = allNonExecutedSteps.map(
                            (ns) => ns.instanceStepId
                        );
                        const index = instanceStepIds.indexOf(
                            parseInt(step.parentInstanceStepId)
                        );
                        allNonExecutedSteps.splice(index + 1, 0, step);
                    });
                    setSelectedInstanceSteps([
                        ...executedSteps,
                        ...allNonExecutedSteps,
                    ]);
                    setSelectedMergeBlocks(mergeSteps);
                    const promiseBlockNames = genericSteps.map(
                        (step) => step.genericStepUniqueIdentifier
                    );
                    const promiseBlocks = instanceSteps.filter((step) =>
                        promiseBlockNames.includes(
                            step.instanceUniqueStepIdentifier
                        )
                    );
                    setBranchViewData({
                        selectedInstanceSteps: [...promiseBlocks],
                        selectedMergeBlocks: mergeSteps,
                        originalInstanceSteps: [...instanceSteps],
                    });
                    dispatch(setUpdatedInstanceBranch(false));
                })
                .catch((error) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        }
    };

    useEffect(() => {
        if (selectedInstance && selectedGenericSteps.length) {
            fetchInstanceList(selectedInstance);
        }
    }, [selectedInstance, selectedGenericSteps]);

    const handleInstanceClick = (selectedInstance) => {
        fetchInstanceList(selectedInstance);
    };
    const moveStep = (fromIndex, toIndex) => {
        const updatedSteps = Array.from(selectedGenericSteps);
        const movedStep = updatedSteps[fromIndex];
        let payload = {
            blockType: movedStep.blockType,
            expectedDuration: movedStep.expectedDuration,
            expectedDurationFrom: movedStep.expectedDurationFrom,
            folderId: movedStep.folderId,
            fromEntity: movedStep.fromEntity,
            genericStepId: movedStep.genericStepId,
            genericStepName: movedStep.genericStepName,
            genericStepUniqueIdentifier: movedStep.genericStepUniqueIdentifier,
            informationAvailable: movedStep.informationAvailable,
            promiseType: movedStep.promiseType,
            spaceId: movedStep.spaceId,
            toEntity: movedStep.toEntity,
            workspaceId: movedStep.workspaceId,
            genericOrderId: updatedSteps[toIndex].genericOrderId,
        };
        const [dragStep] = updatedSteps.splice(fromIndex, 1);
        updatedSteps.splice(toIndex, 0, dragStep);
        dragDropStep(payload, updatedSteps[toIndex - 1]?.genericStepId);
    };
    const handleCloseInstanceDropdown = (e) => {
        if (e && e.target && e.target.innerHTML) {
            const instance = selectedGenericInstances.filter(
                (instance) => instance.instanceId === parseInt(e.target.id)
            );

            setSelectedInstance(instance.length ? instance[0] : null);
        }
    };
    const generateFamilyChipsArray = (currentFamilyTagsString) => {
        let chipTagsArray = [];
        if (
            currentFamilyTagsString !== undefined &&
            currentFamilyTagsString !== null &&
            currentFamilyTagsString !== ""
        ) {
            let chipsArray = currentFamilyTagsString.split("|");
            chipsArray.forEach((word, index) => {
                chipTagsArray.push({ key: index, label: word });
            });
        }
        return chipTagsArray;
    };

    const addStepHandler = (previousStep, isInstanceStep) => {
        if (previousStep) {
            if (isInstanceStep && previousStep.instanceStepId)
                setParentStepId(previousStep.instanceStepId);
            else if (previousStep.genericStepId)
                setParentStepId(previousStep.genericStepId);
        } else {
            if (isInstanceStep)
                setParentStepId(
                    (selectedInstanceSteps[selectedInstanceSteps.length - 1] &&
                        selectedInstanceSteps[selectedInstanceSteps.length - 1]
                            .instanceStepId) ||
                        -1
                );
            else
                setParentStepId(
                    (selectedGenericSteps[selectedGenericSteps.length - 1] &&
                        selectedGenericSteps[selectedGenericSteps.length - 1]
                            .genericStepId) ||
                        -1
                );
        }
        setShowAddStepModal(true);
        setIsChecked(false);
    };
    const editExistingStep = (payload, isInstanceStep, noChange) => {
        setShowAddStepModal(false);
        if (noChange) {
            setSnackbarMessage("You did not change any data!");
            setShowSnackbar(true);
            return;
        }
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/generic/step?genericId=${selectedRow.genericId}&genericStepId=${payload.genericStepId}`,
            "PUT",
            payload
        )
            .then(() => {
                http.sendRestRequest(
                    `documentChain/generic/stepList?genericId=${selectedRow.genericId}`
                )
                    .then((result) => {
                        result.sort((a, b) => {
                            return a.genericOrderId - b.genericOrderId;
                        });
                        setSnackbarMessage(
                            "Generic step updated successfully!"
                        );
                        fetchLatestFlowsData();
                        setSelectedGenericSteps(result);
                        setShowSnackbar(true);
                        setShowLoader(false);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            })
            .catch((error) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const dragDropStep = (payload, prevStepId) => {
        setShowAddStepModal(false);
        const previousStepId = prevStepId ? prevStepId : 0;
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/generic/stepOrder?genericId=${selectedRow.genericId}&previousStepId=${previousStepId}`,
            "PUT",
            payload
        )
            .then(() => {
                // Fetch the updated list of generic steps
                return http.sendRestRequest(
                    `documentChain/generic/stepList?genericId=${selectedRow.genericId}`
                );
            })
            .then((result) => {
                // Sort the steps by genericOrderId
                result.sort((a, b) => a.genericOrderId - b.genericOrderId);

                // Update the UI with the new list of steps
                setSnackbarMessage("Generic step updated successfully!");
                fetchLatestFlowsData();
                setSelectedGenericSteps(result);
                setShowSnackbar(true);
                setShowLoader(false);
            })
            .catch((error) => {
                // Handle errors and display an alert dialog
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };

    const addNewStep = (payload, isInstanceStep, useDefaultStorage) => {
        setShowAddStepModal(false);
        setOpenInstanceStepModal(false);
        setShowLoader(true);
        if (isInstanceStep) {
            const addInstanceStepPayload = {
                instanceStepUniqueIdentifier:
                    payload.genericStepUniqueIdentifier,
                from: payload.fromEntity,
                to: payload.toEntity,
                instanceId: selectedInstance.instanceId,
                parentInstanceStepId: parentStepId.toString(),
                expectedDuration: payload.expectedDuration,
                expectedDurationFrom: payload.expectedDurationFrom,
                expectedDate: payload.expectedDate,
                workspaceId: payload.workspaceId,
                spaceId: payload.spaceId,
                folderIdPath: payload.folderIdPath,
                instanceStepName: payload.genericStepName,
                branchId: payload.branchId,
                branchName: payload.branchName,
            };
            http.sendRestRequest(
                `documentChain/instance/step?genericId=${selectedRow.genericId}`,
                "POST",
                addInstanceStepPayload
            )
                .then(() => {
                    http.sendRestRequest(
                        `documentChain/exception/instance?genericId=${selectedRow.genericId}&instanceId=${selectedInstance.instanceId}`
                    ).then((response) => {
                        const processedData = processExceptions(response);
                        http.sendRestRequest(
                            `documentChain/instanceData?genericId=${selectedRow.genericId}&instanceId=${selectedInstance.instanceId}`
                        )
                            .then((instanceData) => {
                                const instanceSteps = instanceData.map(
                                    (instance) => {
                                        return {
                                            ...instance,
                                            exceptions:
                                                processedData[
                                                    instance.instanceStepId
                                                ] || [],
                                        };
                                    }
                                );
                                const genericSteps = [
                                    ...selectedGenericSteps,
                                ].sort((a, b) => {
                                    return (
                                        parseInt(a.genericOrderId) -
                                        parseInt(b.genericOrderId)
                                    );
                                });
                                const mergeSteps = instanceSteps.filter(
                                    (step) => step.blockType === "MERGE"
                                );
                                const executedSteps = instanceSteps
                                    .filter((step) => step.actualDate)
                                    .sort((a, b) => {
                                        const date1 = new Date(a.actualDate);
                                        const date2 = new Date(b.actualDate);
                                        return date1 - date2;
                                    });
                                const nonExecutedStepsWithExpectedDate =
                                    instanceSteps
                                        .filter(
                                            (step) =>
                                                step.expectedDate &&
                                                step.instanceBlockDocumentName ===
                                                    "" &&
                                                step.durationStatus !==
                                                    "SKIPPED"
                                        )
                                        .sort((a, b) => {
                                            return (
                                                parseInt(a.expectedDate) -
                                                parseInt(b.expectedDate)
                                            );
                                        });
                                const skippedSteps = instanceSteps
                                    .filter(
                                        (step) =>
                                            step.durationStatus === "SKIPPED"
                                    )
                                    .sort(
                                        (a, b) =>
                                            b.instanceStepId - a.instanceStepId
                                    );
                                skippedSteps.forEach((step) => {
                                    if (step.parentInstanceStepId === "0") {
                                        executedSteps.splice(0, 0, step);
                                    } else {
                                        const index = genericSteps
                                            .map((step) => step.genericStepName)
                                            .indexOf(step.instanceStepName);
                                        const genStep = genericSteps[index + 1];
                                        if (genStep) {
                                            let sourceStep =
                                                executedSteps.filter(
                                                    (executedStep) =>
                                                        executedStep.instanceStepName ===
                                                            genStep.genericStepName &&
                                                        executedStep.branchId ===
                                                            step.branchId
                                                );
                                            if (sourceStep.length) {
                                                let index =
                                                    executedSteps.findIndex(
                                                        (executedStep) =>
                                                            executedStep.instanceStepName ===
                                                            sourceStep[0]
                                                                .instanceStepName
                                                    );
                                                executedSteps.splice(
                                                    index,
                                                    0,
                                                    step
                                                );
                                            }
                                        } else executedSteps.push(step);
                                    }
                                });
                                const nonExecutedSteps = instanceSteps.filter(
                                    (step) =>
                                        step.actualDate === "" &&
                                        step.expectedDate === "" &&
                                        step.durationStatus !== "SKIPPED"
                                );
                                let sortedNonExecutedSteps = [];
                                genericSteps.forEach((genStep) => {
                                    let instanceSteps = nonExecutedSteps.filter(
                                        (instanceStep) =>
                                            genStep.genericStepName ===
                                            instanceStep.instanceStepName
                                    );
                                    // .sor((a, b) => a.branchId - b.branchId);
                                    sortedNonExecutedSteps = [
                                        ...sortedNonExecutedSteps,
                                        ...instanceSteps,
                                    ];
                                });
                                const allNonExecutedSteps = [
                                    ...nonExecutedStepsWithExpectedDate,
                                    ...sortedNonExecutedSteps,
                                ];
                                const genStepNames = genericSteps.map(
                                    (step) => step.genericStepName
                                );
                                const customNonExecutedSteps =
                                    nonExecutedSteps.filter(
                                        (step) =>
                                            !genStepNames.includes(
                                                step.instanceStepName
                                            ) &&
                                            step.instanceUniqueStepIdentifier !==
                                                "SPLIT" &&
                                            step.instanceUniqueStepIdentifier !==
                                                "MERGE"
                                    );
                                customNonExecutedSteps.forEach((step) => {
                                    const instanceStepIds =
                                        allNonExecutedSteps.map(
                                            (ns) => ns.instanceStepId
                                        );
                                    const index = instanceStepIds.indexOf(
                                        parseInt(step.parentInstanceStepId)
                                    );
                                    allNonExecutedSteps.splice(
                                        index + 1,
                                        0,
                                        step
                                    );
                                });
                                setSelectedInstanceSteps([
                                    ...executedSteps,
                                    ...allNonExecutedSteps,
                                ]);
                                setSelectedMergeBlocks(mergeSteps);
                                const promiseBlockNames = genericSteps.map(
                                    (step) => step.genericStepUniqueIdentifier
                                );
                                const promiseBlocks = instanceSteps.filter(
                                    (step) =>
                                        promiseBlockNames.includes(
                                            step.instanceUniqueStepIdentifier
                                        )
                                );
                                setBranchViewData({
                                    selectedInstanceSteps: [...promiseBlocks],
                                    selectedMergeBlocks: mergeSteps,
                                    originalInstanceSteps: [...instanceSteps],
                                });
                                setShowSnackbar(true);
                                setSnackbarMessage(
                                    "Instance step added successfully!"
                                );
                                setSelectedMergeBlocks(mergeSteps);
                                dispatch(fetchFilterData());
                                setShowLoader(false);
                            })
                            .catch((error) => {
                                setShowLoader(false);
                                setShowAlertDialog(true);
                                setAlertDialogMessage(error.error);
                            });
                    });
                })
                .catch((error) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        } else {
            http.sendRestRequest(
                `documentChain/generic/step?genericId=${selectedRow.genericId}&previousStepId=${parentStepId}&useDefaultStorage=${useDefaultStorage}`,
                "POST",
                payload
            )
                .then(() => {
                    http.sendRestRequest(
                        `documentChain/generic/stepList?genericId=${selectedRow.genericId}`
                    )
                        .then((result) => {
                            result.sort((a, b) => {
                                return a.genericOrderId - b.genericOrderId;
                            });
                            setSnackbarMessage(
                                "Generic step added successfully!"
                            );
                            fetchLatestFlowsData();
                            setSelectedGenericSteps(result);
                            setShowSnackbar(true);
                            setShowLoader(false);
                        })
                        .catch((error) => {
                            setShowLoader(false);
                            setShowAlertDialog(true);
                            setAlertDialogMessage(error.error);
                        });
                })
                .catch((error) => {
                    showAlertView(
                        "Operation Failed",
                        error.error,
                        "",
                        "",
                        true
                    );
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.path);
                });
        }
    };

    const openAddInstanceModal = (e) => {
        handleClose();
        setShowAddInstanceModal(true);
        instanceTitle.current = e.target.innerText;
    };
    const openMergeInstanceStepsModal = (e) => {
        handleClose();
        setShowMergeInstanceStepsModal(true);
    };
    const openShareStepsModal = (e) => {
        handleClose();
        setShowGenericStepsModal(true);
    };
    const openFlowInfoModal = (e) => {
        let url =
            "documentChain/instance/starred/list?genericId=" +
            selectedRow.genericId;

        http.sendRestRequest(url, "GET")
            .then((resp) => {
                setStarredInstances(resp);
            })
            .catch((err) => {
                console.log(err);
            });
        let starredUrl = `userInteractions/flow/recentlyViewed?genericId=${selectedRow.genericId}`;
        http.sendRestRequest(starredUrl, "GET")
            .then((resp) => {
                setDisplayViewData(resp);
            })
            .catch((error) => {
                console.log(error);
            });
        handleClose();
        setShowFlowModal(true);
    };

    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            if (isDelete === "DELETE") {
                setShowLoader(true);
                const body = selectedInstance.instanceId;
                deleteItems(body)
                    .then((resp) => {
                        setShowLoader(false);
                        if (resp === 200) {
                            setSnackbarMessage(
                                "Instance Deleted successfully!"
                            );
                            setShowSnackbar(true);
                        } else {
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err.error,
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        }
    };

    const closeModal = () => {
        setShowAddInstanceModal(false);
        setShowMergeInstanceStepsModal(false);
        setShowGenericStepsModal(false);
        setShowFlowModal(false);
    };

    const addBranch = (
        branchId,
        branchName,
        instanceId,
        instanceStepId,
        setBranchShow,
        checkedOne
    ) => {
        setShowLoader(true);
        setBranchShow(false);
        http.sendRestRequest(
            `documentChain/instance/step/split?genericId=${
                selectedRow.genericId
            }&branchId=${branchId}&branchName=${encodeURIComponent(
                branchName
            )}&instanceStepId=${instanceStepId}&createPromiseBlocks=${checkedOne}`,
            "PUT"
        )
            .then(() => {
                setSnackbarMessage("Branch Added Successfully!");
                setShowSnackbar(true);
                handleInstanceClick(selectedInstance);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                showAlertView("Operation Failed", error.error, "", "", true);
            });
    };
    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
            })
            .catch((err) => console.log(err));
    };
    const addNewInstance = (name, tags, branchId, branchName) => {
        setShowLoader(true);
        setShowAddInstanceModal(false);
        setBranchId(branchId);
        setBranchsName(branchName);
        let encodedFamilyTags = encodeURIComponent(tags);

        http.sendRestRequest(
            `documentChain/instance?genericId=${
                selectedRow.genericId
            }&instanceName=${encodeURIComponent(
                name
            )}&familyTags=${encodedFamilyTags}&branchId=${branchId}&branchName=${branchName}`,
            "POST"
        )
            .then(() => {
                setSnackbarMessage("Instance created successfully!");
                setShowSnackbar(true);
                fetchLatestFlowsData();
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.genericId}`
                )
                    .then((instanceData) => {
                        setShowLoader(false);
                        const prevInstances = [...selectedGenericInstances].map(
                            (instance) => instance.instanceId
                        );
                        setSelectedGenericInstances(instanceData);
                        const newInstance = instanceData.filter(
                            (instance) =>
                                !prevInstances.includes(instance.instanceId)
                        );
                        setSelectedInstance(newInstance[0]);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
                setRecentlyCreatedInstance(name);
            })
            .catch((error) => {
                setShowLoader(false);
                setSnackbarMessage(error.error);
                setShowSnackbar(true);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const editNewInstance = (data) => {
        setShowLoader(true);
        setShowAddInstanceModal(false);
        http.sendRestRequest(
            `documentChain/instance?genericId=${selectedRow.genericId}`,
            "PUT",
            data
        )
            .then(() => {
                setSnackbarMessage("Instance Updated successfully!");
                setShowSnackbar(true);
                fetchLatestFlowsData();
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.genericId}`
                )
                    .then((instanceData) => {
                        setShowLoader(false);
                        setSelectedGenericInstances(instanceData);
                        const newInstance = instanceData.filter(
                            (instance) =>
                                instance.instanceId === data.instanceId
                        );

                        setSelectedInstance(newInstance[0]);
                    })

                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
                setRecentlyCreatedInstance(data.instanceName);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };

    const deleteItems = (id) => {
        if (id > 0) {
            return new Promise((resolve, reject) => {
                let url = `documentChain/instance?genericId=${selectedRow.genericId}&instanceId=${id}`;

                http.sendRestRequest(url, "DELETE", id)
                    .then((resp) => {
                        resolve(resp);
                        fetchLatestFlowsData();
                        http.sendRestRequest(
                            `documentChain/instance/list?genericId=${selectedRow.genericId}`
                        )
                            .then((instanceData) => {
                                setSelectedGenericInstances(instanceData);
                                setSelectedInstance(null);
                            })
                            .catch((error) => {
                                setShowLoader(false);
                                setShowAlertDialog(true);
                                setAlertDialogMessage(error.error);
                            });

                        setShowSnackbar(false);
                        setSelectedInstanceSteps([]);
                        setBranchViewData(null);
                        setRecentlyCreatedInstance("");
                        handleClose();
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        }
    };

    const deleteClickHandler = (e) => {
        setAlertDialogContent({
            title: "Delete Instance",
            message: "Are you sure you want to Delete the selected item?",
            isAlert: true,
            buttonName: "Delete",
            buttonId: "btn_delete_instance",
        });
        setAlertDialogShow(true);
        handleClose();
    };

    const uploadFileFromUC = (fileData, instanceDetails, genericId) => {
        let payload = {
            instanceBlockDocumentId: fileData.documentCuboidId,
            instanceBlockDocumentHeaderId: fileData.headerCuboidId,
            instanceBlockDocumentName: fileData.documentName,
        };
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/instance/block?genericId=${genericId}`,
            "POST",
            payload
        )
            .then((uploadResponse) => {
                let payload = {
                    instanceStepId: instanceDetails.instanceStepId,
                    instanceBlockId: uploadResponse.instanceBlockId,
                };
                http.sendRestRequest(
                    `documentChain/instance/step/updateBlock?genericId=${genericId}`,
                    "PUT",
                    payload
                )
                    .then((success) => {
                        setSnackbarMessage("Document added successfully!");
                        setShowSnackbar(true);
                        setShowLoader(false);
                        fetchInstanceList(selectedInstance);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const onChangeFileUpload = (
        event,
        instanceDetails,
        genericId,
        previousStep
    ) => {
        let formFileData = event.target.files;
        let fileSize = formFileData[0].size;
        let maxSizeLimit = window.runtimeConfig.maxFileSize; //in mb
        let formFile = Array.from(formFileData);

        let fileName = formFile[0].name;
        let isValidFileType = validateFileType(fileName);
        if (!isValidFileType) {
            setShowAlertDialog(true);
            showAlertView(
                "Alert",
                "File Type Is Not Supported.",
                "fileUploadErroMsg",
                "fileUploadErroBtn",
                true
            );
            return;
        }

        let fileSizeInMB = fileSize / 1024 / 1024;

        if (fileSizeInMB > maxSizeLimit) {
            setShowAlertDialog(true);
            showAlertView(
                "Alert",
                "File size should be maximum " + maxSizeLimit + " MB",
                "fileUploadErroMsg",
                "fileUploadErroBtn",
                true
            );
            return;
        }
        if (!instanceDetails.parentInstanceStepId) {
            setShowAlertDialog(true);
            setAlertDialogMessage(
                "Not allowed to upload the document, Step does not have parent link"
            );
            return;
        }
        if (formFileData.length > 0) {
            const data = new FormData();
            data.append(
                "file",
                formFileData[0],
                encodeURIComponent(formFileData[0].name)
            );
            setShowLoader(true);
            http.uploadFile(
                data,
                `rest/documentChain/instance/step/updateBlock?genericId=${genericId}&instanceStepId=${instanceDetails.instanceStepId}`,
                "PUT"
            )
                .then((success) => {
                    let splitFileName = formFileData[0].name.split(".");
                    let fileExt = splitFileName.pop();
                    let fileSize = fileSizeInMB + "MB";
                    Mixpanel.callFileManagementEvent(
                        "Upload",
                        fileExt,
                        fileSize
                    );
                    setSnackbarMessage("Document added successfully!");
                    setShowSnackbar(true);
                    setShowLoader(false);
                    fetchInstanceList(selectedInstance);
                })
                .catch((error) => {
                    showAlertView(
                        "Operation Failed",
                        error.error,
                        "",
                        "",
                        true
                    );
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        }
    };

    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    function sortAscending(a, b) {
        if (
            a[orderByColumn.current].toUpperCase() <
            b[orderByColumn.current].toUpperCase()
        ) {
            return -1;
        }
        if (
            a[orderByColumn.current].toUpperCase() >
            b[orderByColumn.current].toUpperCase()
        ) {
            return 1;
        }
        return 0;
    }

    const openShareFlow = () => {
        setShareFile(true);
    };
    function sortDescending(a, b) {
        if (
            b[orderByColumn.current].toUpperCase() <
            a[orderByColumn.current].toUpperCase()
        ) {
            return -1;
        }
        if (
            b[orderByColumn.current].toUpperCase() >
            a[orderByColumn.current].toUpperCase()
        ) {
            return 1;
        }
        return 0;
    }

    const sortData = (columnName, orderBy) => {
        switch (columnName) {
            case "Name":
                orderByColumn.current = "genericDisplayName";
                break;
            case "Date Created":
                orderByColumn.current = "createdOn";
                break;
            case "Date Modified":
                orderByColumn.current = "updatedOn";
                break;
            case "Actions":
                return;
        }

        let sortedData = [...documentChainList].sort(
            orderBy === "asc" ? sortAscending : sortDescending
        );
        setDocumentChainList([]);
        setDocumentChainList(sortedData);
    };
    const editInstanceStep = (data) => {
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/instance/step?genericId=${selectedRow.genericId}`,
            "PUT",
            data
        );
        http.sendRestRequest(
            `documentChain/exception/instance?genericId=${selectedRow.genericId}&instanceId=${selectedInstance.instanceId}`
        )
            .then((response) => {
                const processedData = processExceptions(response);
                http.sendRestRequest(
                    `documentChain/instanceData?genericId=${selectedRow.genericId}&instanceId=${selectedInstance.instanceId}`
                )
                    .then((instanceData) => {
                        const instanceSteps = instanceData.map((instance) => {
                            return {
                                ...instance,
                                exceptions:
                                    processedData[instance.instanceStepId] ||
                                    [],
                            };
                        });
                        const genericSteps = [...selectedGenericSteps].sort(
                            (a, b) => {
                                return (
                                    parseInt(a.genericOrderId) -
                                    parseInt(b.genericOrderId)
                                );
                            }
                        );
                        const mergeSteps = instanceSteps.filter(
                            (step) => step.blockType === "MERGE"
                        );
                        const executedSteps = instanceSteps
                            .filter((step) => step.actualDate)
                            .sort((a, b) => {
                                const date1 = new Date(a.actualDate);
                                const date2 = new Date(b.actualDate);
                                return date1 - date2;
                            });
                        const nonExecutedStepsWithExpectedDate = instanceSteps
                            .filter(
                                (step) =>
                                    step.expectedDate &&
                                    step.instanceBlockDocumentName === "" &&
                                    step.durationStatus !== "SKIPPED"
                            )
                            .sort((a, b) => {
                                return (
                                    parseInt(a.expectedDate) -
                                    parseInt(b.expectedDate)
                                );
                            });
                        const skippedSteps = instanceSteps
                            .filter((step) => step.durationStatus === "SKIPPED")
                            .sort(
                                (a, b) => b.instanceStepId - a.instanceStepId
                            );
                        skippedSteps.forEach((step) => {
                            if (step.parentInstanceStepId === "0") {
                                executedSteps.splice(0, 0, step);
                            } else {
                                const index = genericSteps
                                    .map((step) => step.genericStepName)
                                    .indexOf(step.instanceStepName);
                                const genStep = genericSteps[index + 1];
                                if (genStep) {
                                    let sourceStep = executedSteps.filter(
                                        (executedStep) =>
                                            executedStep.instanceStepName ===
                                                genStep.genericStepName &&
                                            executedStep.branchId ===
                                                step.branchId
                                    );
                                    if (sourceStep.length) {
                                        let index = executedSteps.findIndex(
                                            (executedStep) =>
                                                executedStep.instanceStepName ===
                                                sourceStep[0].instanceStepName
                                        );
                                        executedSteps.splice(index, 0, step);
                                    }
                                } else executedSteps.push(step);
                            }
                        });
                        const nonExecutedSteps = instanceSteps.filter(
                            (step) =>
                                step.actualDate === "" &&
                                step.expectedDate === "" &&
                                step.durationStatus !== "SKIPPED"
                        );
                        let sortedNonExecutedSteps = [];
                        genericSteps.forEach((genStep) => {
                            let instanceSteps = nonExecutedSteps.filter(
                                (instanceStep) =>
                                    genStep.genericStepName ===
                                    instanceStep.instanceStepName
                            );
                            sortedNonExecutedSteps = [
                                ...sortedNonExecutedSteps,
                                ...instanceSteps,
                            ];
                        });
                        const allNonExecutedSteps = [
                            ...nonExecutedStepsWithExpectedDate,
                            ...sortedNonExecutedSteps,
                        ];
                        const genStepNames = genericSteps.map(
                            (step) => step.genericStepName
                        );
                        const customNonExecutedSteps = nonExecutedSteps.filter(
                            (step) =>
                                !genStepNames.includes(step.instanceStepName) &&
                                step.instanceUniqueStepIdentifier !== "SPLIT" &&
                                step.instanceUniqueStepIdentifier !== "MERGE"
                        );
                        customNonExecutedSteps.forEach((step) => {
                            const instanceStepIds = allNonExecutedSteps.map(
                                (ns) => ns.instanceStepId
                            );
                            const index = instanceStepIds.indexOf(
                                parseInt(step.parentInstanceStepId)
                            );
                            allNonExecutedSteps.splice(index + 1, 0, step);
                        });
                        setSelectedInstanceSteps([
                            ...executedSteps,
                            ...allNonExecutedSteps,
                        ]);
                        setSelectedMergeBlocks(mergeSteps);
                        const promiseBlockNames = genericSteps.map(
                            (step) => step.genericStepUniqueIdentifier
                        );
                        const promiseBlocks = instanceSteps.filter((step) =>
                            promiseBlockNames.includes(
                                step.instanceUniqueStepIdentifier
                            )
                        );
                        setBranchViewData({
                            selectedInstanceSteps: [...promiseBlocks],
                            selectedMergeBlocks: mergeSteps,
                            originalInstanceSteps: [...instanceSteps],
                        });
                        setSnackbarMessage(
                            "Instance step updated successfully!"
                        );
                        setSelectedMergeBlocks(mergeSteps);
                        fetchLatestFlowsData();
                        setShowSnackbar(true);
                        setShowLoader(false);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };

    const handleFlowRule = () => {
        setShowFlowRuleModal(true);
    };
    const getInstanceData = () => {
        http.sendRestRequest(
            `documentChain/instance/list?genericId=${selectedRow.genericId}`
        )
            .then((instanceData) => {
                setSelectedGenericInstances(instanceData);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    if (showDocumentChain)
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        borderBottom: "1px solid #E0E0E0",
                        padding: "16px 0px 0px 32px",
                        border: "0px 0px 1px 0px",
                        height: "100px",
                    }}
                >
                    <div
                        className="library"
                        onClick={() => {
                            setShowDocumentChain(false);
                            setEditGeneric(false);
                            setSelectedInstance(null);
                            setSelectedInstanceSteps([]);
                            setIsBranchView(false);
                            setBranchViewData(null);
                            changeOpenDialogFlag(false);
                        }}
                        style={{
                            width: "76px",
                            padding: "6px 12px 8px 12px",
                            borderRadius: "4px",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <div>
                                <img
                                    alt="back"
                                    src="./images/back.svg"
                                    id="flow_icon"
                                    title="Back"
                                    className="bw_plusButton "
                                ></img>
                            </div>
                        </div>

                        <p
                            style={{
                                width: "52px",
                                height: "24px",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                letterSpacing: "0.4000000059604645px",
                                textAlign: "left",
                                marginTop: "-6px",
                            }}
                        >
                            Library
                        </p>
                    </div>
                    {editGeneric ? null : (
                        <>
                            <div
                                id="div_editGeneric_text"
                                className={
                                    isReadOnlyUser
                                        ? "editGeneric readOnly"
                                        : "editGeneric"
                                }
                                style={isReadOnlyUser ? {} : { width: "117px" }}
                                onClick={() => {
                                    if (isReadOnlyUser) {
                                        return;
                                    } else {
                                        setShowDocumentChain(true);
                                        setEditGeneric(true);
                                    }
                                }}
                                title={"Edit Generic"}
                            >
                                <div>
                                    <img
                                        alt="Edit Generic"
                                        src="./images/edit.svg"
                                        id="flow_icon"
                                        className={
                                            isReadOnlyUser
                                                ? "bw_plusButton  readOnly"
                                                : "bw_plusButton "
                                        }
                                    ></img>
                                </div>

                                <p
                                    style={{
                                        width: "93px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        letterSpacing: "0.4px",
                                        textAlign: "left",
                                        marginTop: "-6px",
                                    }}
                                >
                                    {"Edit Generic"}
                                </p>
                            </div>
                        </>
                    )}
                    {editGeneric ? null : (
                        <>
                            <div
                                id="div_shareGeneric_text"
                                className={
                                    isReadOnlyUser
                                        ? "editGeneric readOnly"
                                        : "editGeneric"
                                }
                                style={isReadOnlyUser ? {} : { width: "133px" }}
                                onClick={openShareStepsModal}
                                title={"Share Generic"}
                            >
                                <div>
                                    <img
                                        alt="Share Generic"
                                        src="./images/share.svg"
                                        id="flow_icon"
                                        className={
                                            isReadOnlyUser
                                                ? "bw_plusButton  readOnly"
                                                : "bw_plusButton "
                                        }
                                    ></img>
                                </div>

                                <p
                                    style={{
                                        width: "109px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        marginTop: "-6px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.4px",
                                        textAlign: "left",
                                    }}
                                >
                                    {"Share Generic"}
                                </p>
                            </div>
                        </>
                    )}
                    {editGeneric ? null : (
                        <>
                            <div
                                id="div_Flow"
                                className={
                                    isReadOnlyUser
                                        ? "editGeneric readOnly"
                                        : "editGeneric"
                                }
                                style={isReadOnlyUser ? {} : { width: "93px" }}
                                onClick={openFlowInfoModal}
                                title={"Flow Info"}
                            >
                                <div id="div_flowInfo_img">
                                    <img
                                        alt="Flow Info"
                                        src="./images/info-circle.svg"
                                        id="flow_icon"
                                        className={
                                            isReadOnlyUser
                                                ? "bw_plusButton  readOnly"
                                                : "bw_plusButton "
                                        }
                                    ></img>
                                </div>

                                <p
                                    id="div_Flow_Text"
                                    style={{
                                        width: "69px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        marginTop: "-6px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        letterSpacing: "0.4px",
                                        textAlign: "left",
                                    }}
                                >
                                    {"Flow Info"}
                                </p>
                            </div>
                        </>
                    )}

                    {editGeneric ? null : (
                        <div
                            id="div_Flow"
                            className={
                                isReadOnlyUser
                                    ? "editGeneric readOnly"
                                    : "editGeneric"
                            }
                            style={isReadOnlyUser ? {} : { width: "93px" }}
                            onClick={handleFlowRule}
                            title={"Flow Rules"}
                        >
                            <div id="div_flowInfo_img">
                                <img
                                    alt="Flow Rules"
                                    src="./images/info-circle.svg"
                                    id="flow_icon"
                                    className={
                                        isReadOnlyUser
                                            ? "bw_plusButton  readOnly"
                                            : "bw_plusButton "
                                    }
                                ></img>
                            </div>

                            <p
                                id="div_Flow_Text"
                                style={{
                                    width: "81px",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    marginTop: "-6px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    letterSpacing: "0.4px",
                                    textAlign: "left",
                                }}
                            >
                                {"Flow Rules"}
                            </p>
                        </div>
                    )}
                    {editGeneric ? null : (
                        <div
                            style={{
                                width: "50px",
                                height: 0,
                                transform: "rotate(90deg)",
                                border: "1px solid #000000",
                                marginTop: "2rem",
                            }}
                        ></div>
                    )}
                    {!editGeneric ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                padding: "9px 0px 9px 0px",
                                marginBottom: "8px",
                            }}
                            onClick={getInstanceData}
                        >
                            <InstanceDropdown
                                selectedInstanceSteps={selectedInstanceSteps}
                                title="Instance Name"
                                selectedGenericInstances={
                                    selectedGenericInstances
                                }
                                handleCloseInstanceDropdown={
                                    handleCloseInstanceDropdown
                                }
                                selectedInstance={selectedInstance}
                            ></InstanceDropdown>
                        </div>
                    ) : null}
                    {editGeneric ? null : (
                        <>
                            <div
                                id="div_NewInstance_text"
                                className={isReadOnlyUser ? "readOnly" : ""}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "6px 12px 8px 12px",
                                    width: "58px",
                                    borderRadius: "4px",
                                }}
                                onClick={
                                    isReadOnlyUser ? null : openAddInstanceModal
                                }
                                title={"New"}
                            >
                                <div>
                                    <img
                                        alt="New"
                                        src="./images/new.svg"
                                        id="flow_icon"
                                        className={
                                            isReadOnlyUser
                                                ? "bw_plusButton  readOnly"
                                                : "bw_plusButton "
                                        }
                                    ></img>
                                </div>
                                <p
                                    style={{
                                        width: "34px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        fontFamily: "Montserrat",
                                        lineHeight: "24px",
                                        letterSpacing: "0.4000000059604645px",
                                        textAlign: "left",
                                        marginTop: "-6px",
                                    }}
                                >
                                    {"New"}
                                </p>
                            </div>
                        </>
                    )}
                    {!editGeneric ? (
                        <div
                            onClick={() => {
                                handleInstanceClick(selectedInstance);
                            }}
                            id="div_instanceRefresh_icon"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "6px 12px 8px 12px",
                                width: "81px",
                                borderRadius: "4px",
                            }}
                        >
                            <div>
                                <img
                                    style={{
                                        width: "18px",
                                        top: "0.43px",
                                        left: "3px",
                                    }}
                                    alt={"refresh"}
                                    title="refresh"
                                    src="./images/refresh.svg"
                                />
                            </div>

                            <p
                                style={{
                                    width: "57px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "Montserrat",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                    marginTop: "-6px",
                                }}
                            >
                                {"Refresh"}
                            </p>
                        </div>
                    ) : null}
                    {!editGeneric ? (
                        <div
                            id="div_instanceOtp_icon"
                            className={isReadOnlyUser ? "readOnly" : ""}
                            onClick={(event) =>
                                isReadOnlyUser ? null : handleClick(event)
                            }
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                                margin: "0px 5px 0px 5px",
                                cursor: "pointer",
                                padding: "6px 12px 8px 12px",
                                width: "83px",
                                borderRadius: "4px",
                            }}
                        >
                            <div
                                style={{
                                    width: "20px",
                                    padding: "0px 1.88px 0px 1.87px",
                                }}
                            >
                                <img
                                    style={{
                                        width: "16.25px",
                                        top: "8.13px",
                                        left: "1.88px",
                                    }}
                                    className={
                                        isReadOnlyUser
                                            ? "readOnly"
                                            : "cursorPointer"
                                    }
                                    alt="Options"
                                    src="./images/opt.svg"
                                    title="Options"
                                ></img>
                            </div>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "Montserrat",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                    marginTop: "-6px",
                                }}
                            >
                                {"Options"}
                            </p>
                        </div>
                    ) : null}
                    {editGeneric && selectedGenericSteps.length ? (
                        <>
                            <div
                                onClick={() => {
                                    setEditGeneric(false);
                                    setShowDocumentChain(true);
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    margin: "0px 25px",
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "18.1px",
                                        paddingRight: "21px",
                                    }}
                                >
                                    Continue To Instance
                                </p>
                                <img
                                    alt="continue"
                                    src="./images/Integration/right-arrow.svg"
                                    id="continueToInstance_icon_image"
                                    title="Continue"
                                    className="bw_plusButton "
                                ></img>
                            </div>
                        </>
                    ) : null}
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        padding: "0px 12px",
                    }}
                >
                    <div
                        style={{
                            width: "571px",
                            gap: "10px",
                        }}
                    >
                        <p className="flow_title textEllipsis">
                            {selectedRow.genericDisplayName}
                        </p>
                    </div>
                    {selectedInstance && !editGeneric ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                width: "482.5px",
                                paddingRight: "63px",
                                gap: "16px",
                            }}
                        >
                            <div>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={activeTab}
                                    exclusive
                                    onChange={(e, active) => {
                                        if (active) {
                                            setActiveTab(active);
                                            active === "Branches"
                                                ? setIsBranchView(true)
                                                : setIsBranchView(false);
                                        }
                                    }}
                                    aria-label="Platform"
                                    style={{ fontFamily: "Montserrat" }}
                                >
                                    <ToggleButton
                                        title="Instance"
                                        value="Instance"
                                        id="Instance"
                                        style={{ fontFamily: "Montserrat" }}
                                    >
                                        Instance
                                    </ToggleButton>
                                    <ToggleButton
                                        title="Branches"
                                        value="Branches"
                                        id="Branches"
                                        style={{ fontFamily: "Montserrat" }}
                                    >
                                        Branches
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div
                                style={{
                                    width: "44px",
                                    padding: "20px",
                                    gap: "10px",
                                }}
                            >
                                {selectedInstance ? (
                                    selectedInstance.isStarred ? (
                                        <img
                                            id={
                                                "img_documentChain_star_" +
                                                selectedInstance.instanceId
                                            }
                                            style={{
                                                width: "24px",
                                                cursor: "pointer",
                                            }}
                                            src="./images/star.png"
                                            alt=""
                                            onClick={() =>
                                                addStar(
                                                    selectedInstance.instanceId,
                                                    selectedInstance.isStarred
                                                )
                                            }
                                        />
                                    ) : (
                                        <img
                                            id={
                                                "img_documentChain_emptyStar_" +
                                                selectedInstance.instanceId
                                            }
                                            src="./images/empstar.png"
                                            alt=""
                                            style={{
                                                width: "24px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                addStar(
                                                    selectedInstance.instanceId,
                                                    selectedInstance.isStarred
                                                )
                                            }
                                        />
                                    )
                                ) : null}
                            </div>
                        </div>
                    ) : editGeneric && selectedGenericSteps.length ? (
                        <div>
                            <ButtonComponent
                                text="Manage Alerts"
                                BtnHandler={toggleDrawer(true)}
                                type={"outlined"}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="docChainContainer">
                    <div
                        className="mainContainer"
                        style={{
                            marginTop: "0px",
                        }}
                    >
                        {editGeneric ? (
                            <div
                                className="genSteps invisibleScroller"
                                style={{
                                    boxShadow: "none",
                                    padding: "36px 25%",
                                    width: "100%",
                                    flexBasis: "100%",
                                    paddingBottom: "60px",
                                }}
                            >
                                {selectedGenericSteps.length ? (
                                    <>
                                        <DndProvider backend={HTML5Backend}>
                                            {selectedGenericSteps.map(
                                                (item, index) => (
                                                    <>
                                                        <GenericStep
                                                            editGeneric={
                                                                editGeneric
                                                            }
                                                            index={index}
                                                            moveStep={moveStep}
                                                            setSelectedGenericSteps={
                                                                setSelectedGenericSteps
                                                            }
                                                            selectedRow={
                                                                selectedRow
                                                            }
                                                            editStep={
                                                                editExistingStep
                                                            }
                                                            genericSteps={
                                                                selectedGenericSteps
                                                            }
                                                            step={item}
                                                            stepIndex={index}
                                                        ></GenericStep>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <img
                                                                src="./images/Arrow 7.png"
                                                                alt="arrow"
                                                            />
                                                            {index ===
                                                            selectedGenericSteps.length -
                                                                1 ? null : (
                                                                <div
                                                                    id={
                                                                        "div_addstep_flow"
                                                                    }
                                                                    title="Add step"
                                                                    className={
                                                                        isReadOnlyUser
                                                                            ? "addStep readOnly"
                                                                            : "addStep"
                                                                    }
                                                                    onClick={() =>
                                                                        isReadOnlyUser
                                                                            ? null
                                                                            : addStepHandler(
                                                                                  item
                                                                              )
                                                                    }
                                                                >
                                                                    <img
                                                                        src="./images/plus.svg"
                                                                        alt="arrow"
                                                                        style={{
                                                                            paddingBottom:
                                                                                "8px",
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </DndProvider>
                                        <AddGeneric
                                            addStep={addStepHandler}
                                        ></AddGeneric>
                                    </>
                                ) : (
                                    <AddGeneric
                                        addStep={addStepHandler}
                                    ></AddGeneric>
                                )}
                            </div>
                        ) : isBranchView ? (
                            <BranchView
                                selectedGenericSteps={selectedGenericSteps}
                                branchViewData={branchViewData}
                            ></BranchView>
                        ) : (
                            <>
                                <div className="geneSteps invisibleScroller">
                                    {selectedGenericSteps.length ? (
                                        <div
                                            style={{
                                                padding: "0px 37px 37px 37px",
                                            }}
                                        >
                                            <h4
                                                style={{
                                                    textAlign: "center",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                Generic Steps
                                            </h4>
                                            <DndProvider backend={HTML5Backend}>
                                                {selectedGenericSteps.map(
                                                    (item, index) => (
                                                        <>
                                                            {/* <DraggableStep
                                                                step={item}
                                                                index={index}
                                                                moveStep={moveStep}
                                                            /> */}
                                                            <GenericStep
                                                                editGeneric={
                                                                    editGeneric
                                                                }
                                                                step={item}
                                                                index={index}
                                                                moveStep={
                                                                    moveStep
                                                                }
                                                                editStep={
                                                                    editExistingStep
                                                                }
                                                                genericSteps={
                                                                    selectedGenericSteps
                                                                }
                                                                setSelectedGenericSteps={
                                                                    setSelectedGenericSteps
                                                                }
                                                                selectedRow={
                                                                    selectedRow
                                                                }
                                                                setEditGeneric={
                                                                    setEditGeneric
                                                                }
                                                                stepIndex={
                                                                    index
                                                                }
                                                            ></GenericStep>
                                                            {index ===
                                                            selectedGenericSteps.length -
                                                                1 ? null : (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="./images/Arrow 7.png"
                                                                        alt="arrow"
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </DndProvider>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                margin: "24px 25px 25px 0px",
                                            }}
                                        >
                                            <AddGeneric
                                                addStep={addStepHandler}
                                            ></AddGeneric>
                                        </div>
                                    )}
                                </div>

                                <div className="geneSteps invisibleScroller">
                                    {selectedInstanceSteps.length ||
                                    selectedGenericInstances.length ? (
                                        <>
                                            <div
                                                className="cardDiv"
                                                style={{
                                                    padding:
                                                        "0px 37px 37px 37px",
                                                }}
                                            >
                                                <h4
                                                    className="textEllipsis"
                                                    style={{
                                                        textAlign: "center",
                                                        marginBottom: "20px",
                                                    }}
                                                    title={
                                                        selectedInstance &&
                                                        selectedInstance.instanceName
                                                    }
                                                >
                                                    {selectedInstance &&
                                                        selectedInstance.instanceName}
                                                </h4>
                                                {selectedInstanceSteps.map(
                                                    (item, index) => (
                                                        <>
                                                            <InstanceStep
                                                                fetchInstanceList={
                                                                    fetchInstanceList
                                                                }
                                                                expectedDateMap={
                                                                    expectedDateMap
                                                                }
                                                                addBranch={
                                                                    addBranch
                                                                }
                                                                handleInstanceClick={
                                                                    handleInstanceClick
                                                                }
                                                                selectedInstance={
                                                                    selectedInstance
                                                                }
                                                                instanceBlockDocumentName={
                                                                    item.instanceBlockDocumentName
                                                                }
                                                                durationStatus={
                                                                    item.durationStatus
                                                                }
                                                                key={
                                                                    item.genericStepId
                                                                }
                                                                instanceDetails={
                                                                    item
                                                                }
                                                                steps={
                                                                    selectedInstanceSteps
                                                                }
                                                                genericId={
                                                                    selectedRow.genericId
                                                                }
                                                                onChangeFileUpload={
                                                                    onChangeFileUpload
                                                                }
                                                                uploadFileFromUC={
                                                                    uploadFileFromUC
                                                                }
                                                                previousStep={
                                                                    selectedInstanceSteps[
                                                                        index -
                                                                            1
                                                                    ]
                                                                }
                                                                editStep={
                                                                    editInstanceStep
                                                                }
                                                                instanceSteps={
                                                                    selectedInstanceSteps
                                                                }
                                                                index={index}
                                                                genericSteps={
                                                                    selectedGenericSteps
                                                                }
                                                            ></InstanceStep>
                                                            {index ===
                                                            selectedInstanceSteps.length -
                                                                1 ? null : (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                        position:
                                                                            "relative",
                                                                        visibility:
                                                                            selectedInstanceSteps[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                .parentInstanceStepId
                                                                                ? "visible"
                                                                                : "hidden",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="./images/Arrow 7.png"
                                                                        alt="arrow"
                                                                    />
                                                                    {((selectedInstanceSteps[
                                                                        index +
                                                                            1
                                                                    ] &&
                                                                        selectedInstanceSteps[
                                                                            index +
                                                                                1
                                                                        ]
                                                                            .durationStatus ===
                                                                            "PENDING") ||
                                                                        selectedInstanceSteps[
                                                                            index +
                                                                                1
                                                                        ]
                                                                            .durationStatus ===
                                                                            "DELAYED" ||
                                                                        (selectedInstanceSteps[
                                                                            index +
                                                                                1
                                                                        ]
                                                                            .durationStatus ===
                                                                            "CUSTOM" &&
                                                                            !selectedInstanceSteps[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                .instanceBlockDocumentName)) &&
                                                                        (item.durationStatus ===
                                                                            "DONE" ||
                                                                            (item.durationStatus ===
                                                                                "DELAYED" &&
                                                                                item.instanceBlockDocumentName) ||
                                                                            (item.durationStatus ===
                                                                                "CUSTOM" &&
                                                                                item.instanceBlockDocumentName)) && (
                                                                            <div
                                                                                id={
                                                                                    "div_addstep_flow"
                                                                                }
                                                                                title="Add step"
                                                                                className={
                                                                                    isReadOnlyUser
                                                                                        ? "addStep readOnly"
                                                                                        : "addStep"
                                                                                }
                                                                                onClick={() =>
                                                                                    isReadOnlyUser
                                                                                        ? null
                                                                                        : addStepHandler(
                                                                                              item,
                                                                                              true
                                                                                          )
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src="./images/plus.svg"
                                                                                    alt="arrow"
                                                                                    style={{
                                                                                        paddingBottom:
                                                                                            "8px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                                {selectedInstanceSteps.length ? null : (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding: "37px",
                                                            margin: "30% auto",
                                                            justifyContent:
                                                                "center",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                padding: "12px",
                                                            }}
                                                        >
                                                            <img src="./images/instance 1.svg" />
                                                        </div>
                                                        <div>
                                                            Please select
                                                            instance to view the
                                                            steps
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <InstanceContainer
                                                showButton={
                                                    selectedGenericSteps.length
                                                }
                                                openAddInstanceModal={
                                                    openAddInstanceModal
                                                }
                                            ></InstanceContainer>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <AddStep
                        showAddDocumentChainModal={openInstanceStepModal}
                        closeModal={() => {
                            setOpenInstanceStepModal(false);
                        }}
                        title={"Add New Step"}
                        btnName={"Create"}
                        addNewStep={addNewStep}
                        genericStepList={selectedGenericSteps}
                        isInstanceStep={!editGeneric}
                    ></AddStep>
                    <DialogView
                        show={showAddStepModal}
                        size="lg"
                        handleClose={setShowAddStepModal}
                        showTitle={false}
                        showFooter={false}
                        onBackdropClick={false}
                        style={
                            displayShow
                                ? { display: "none" }
                                : { display: "block" }
                        }
                    >
                        <AddStepStepper
                            setIsChecked={setIsChecked}
                            isChecked={isChecked}
                            selectedRow={selectedRow}
                            Display={setDisplayShow}
                            onHide={setShowAddStepModal}
                            hideFile={"true"}
                            saveButtonName={"NEXT"}
                            genericStepList={selectedGenericSteps}
                            isInstanceStep={!editGeneric}
                            title={"Add New Step"}
                            curruntAction={"create"}
                            addNewStep={addNewStep}
                            selectedInstanceSteps={selectedInstanceSteps.map(
                                (step) => {
                                    return {
                                        ...step,
                                        genericStepUniqueIdentifier:
                                            step.instanceUniqueStepIdentifier,
                                        genericStepName: step.instanceStepName,
                                    };
                                }
                            )}
                            parentStepId={parentStepId}
                            genericSteps={selectedGenericSteps}
                        ></AddStepStepper>
                    </DialogView>
                </div>
                {selectedInstance !== null ? (
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={openAddInstanceModal}>
                            <div id="div_instance_editIcon">
                                <img
                                    className="renameIcon"
                                    src="./images/Rename.svg"
                                    alt="Edit"
                                    title="Edit"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span className="btn_title" title="Edit">
                                    Edit
                                </span>
                            </div>
                        </MenuItem>
                        <MenuItem onClick={deleteClickHandler}>
                            <div id="div_instance_deleteIcon">
                                <img
                                    className="deleteIcon"
                                    src="./images/delete.svg"
                                    alt="Delete"
                                    title="Delete"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span className="btn_title" title="Delete">
                                    Delete
                                </span>
                            </div>
                        </MenuItem>
                        <MenuItem onClick={openMergeInstanceStepsModal}>
                            <div id="div_instance_mergeIcon">
                                <img
                                    className="renameIcon"
                                    src="./images/fileTypeIcon/merge-icon.svg"
                                    alt="Merge"
                                    title="Merge"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span className="btn_title" title="Merge">
                                    Merge
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                ) : (
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem disabled onClick={openAddInstanceModal}>
                            <div id="div_instance_editIcon">
                                <img
                                    className="renameIcon"
                                    src="./images/Rename.svg"
                                    alt="Edit"
                                    title="Edit"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span className="btn_title" title="Edit">
                                    Edit
                                </span>
                            </div>
                        </MenuItem>
                        <MenuItem disabled onClick={deleteClickHandler}>
                            <div id="div_instance_deleteIcon">
                                <img
                                    className="deleteIcon"
                                    src="./images/delete.svg"
                                    alt="Delete"
                                    title="Delete"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span className="btn_title" title="Delete">
                                    Delete
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                )}

                <AddInstance
                    selectedGenericInstances={selectedGenericInstances}
                    selectedInstance={selectedInstance}
                    showAddInstanceModal={showAddInstanceModal}
                    closeModal={closeModal}
                    currentFamilyTags={
                        instanceTitle.current === "Edit"
                            ? generateFamilyChipsArray(
                                  selectedInstance !== null
                                      ? selectedInstance.familyTags
                                      : null
                              )
                            : []
                    }
                    currentInstanceName={
                        instanceTitle.current === "Edit" &&
                        selectedInstance !== null
                            ? selectedInstance.instanceName
                            : ""
                    }
                    heading={
                        instanceTitle.current === "Edit"
                            ? "Edit Instance"
                            : "Add New Instance"
                    }
                    branchsId={
                        instanceTitle.current === "Edit" &&
                        selectedInstance !== null
                            ? branchsId
                            : "1"
                    }
                    branchsName={
                        instanceTitle.current === "Edit" &&
                        selectedInstance !== null
                            ? branchsName
                            : "1"
                    }
                    disabled={
                        instanceTitle.current === "Edit" &&
                        selectedInstance !== null
                            ? true
                            : false
                    }
                    editNewInstance={editNewInstance}
                    addNewInstance={addNewInstance}
                ></AddInstance>
                <MergeInstanceSteps
                    showMergeInstanceStepsModal={showMergeInstanceStepsModal}
                    selectedInstanceSteps={getPossibleTargetSteps(
                        branchViewData
                    )}
                    genericId={selectedRow.genericId}
                    instanceId={
                        selectedInstance ? selectedInstance.instanceId : 0
                    }
                    selectedInstance={selectedInstance}
                    fetchInstanceData={fetchInstanceList}
                    closeModal={closeModal}
                    branchViewData={branchViewData}
                ></MergeInstanceSteps>
                {showGenericStepsModal ? (
                    <DndProvider backend={HTML5Backend}>
                        <ShareGenericSteps
                            selectedStepId={selectedStepId}
                            setSelectedStepId={setSelectedStepId}
                            closeModal={closeModal}
                            openShareFlow={openShareFlow}
                            selectedGenericSteps={selectedGenericSteps}
                            setShowGenericStepsModal={setShowGenericStepsModal}
                            showGenericStepsModal={showGenericStepsModal}
                        ></ShareGenericSteps>
                    </DndProvider>
                ) : null}
                <Members
                    id={selectedRow.genericId}
                    heading={selectedRow.genericDisplayName}
                    setMembersList={setMembersList}
                    showMembersModal={showMembersModal}
                    onHide={setShowMembersModal}
                    currentMembers={currentMembers}
                    setCurrentMembers={setCurrentMembers}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    membersList={membersList}
                ></Members>
                {showFlowModal ? (
                    <WorkspaceModalInfo
                        heading={selectedRow.genericDisplayName}
                        open={showFlowModal}
                        workspaceName=""
                        spaceListValue={starredInstances}
                        onHide={setShowMembersModal}
                        handleOpen={openFlowInfoModal}
                        handleClose={closeModal}
                        displayViewData={displayViewData}
                        membersList={membersList}
                        selectedGenericInstances={selectedGenericInstances}
                        setSelectedInstance={setSelectedInstance}
                        isFlowInfoModal={true}
                        selectedRow={selectedRow}
                        setMembersList={setMembersList}
                        setCurrentMembers={setCurrentMembers}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                    ></WorkspaceModalInfo>
                ) : null}
                {showFlowRuleModal ? (
                    <FlowRule
                        open={showFlowRuleModal}
                        onClose={setShowFlowRuleModal}
                        genericId={selectedRow.genericId}
                        genericName={selectedRow.genericDisplayName}
                        selectedGenericSteps={selectedGenericSteps.map(
                            (step) => step.genericStepName
                        )}
                        // existingData={flowRuleData}
                        showLoader={setShowLoader}
                        setShowLoader={setShowLoader}
                    />
                ) : null}
                <Loader show={showLoader}></Loader>
                <CustomSnackbar
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    alertMessage={snackbarMessage}
                    severity={
                        snackbarMessage === "Instance name already exists"
                            ? "error"
                            : "success"
                    }
                ></CustomSnackbar>
                <AlertDialog
                    open={alertDialogShow}
                    setOpen={setAlertDialogShow}
                    title={alertDialogContent.title}
                    handleConfirmationDialog={handleConfirmationDialog}
                    message={alertDialogContent.message}
                    isAlert={alertDialogContent.isAlert}
                    messageId={alertDialogContent.messageId}
                    buttonId={alertDialogContent.buttonId}
                    buttonName={alertDialogContent.buttonName}
                ></AlertDialog>
                <ShareFlow
                    genericId={selectedRow.genericId}
                    genericDetails={selectedRow}
                    selectedStepId={selectedStepId}
                    showShareModal={shareFile}
                    closeModal={closeShareClick}
                    selectedGenericSteps={selectedGenericSteps}
                />
                {openDrawer && (
                    <Drawer
                        open={openDrawer}
                        onClose={toggleDrawer(false)}
                        anchor="right"
                    >
                        <ManageAlerts
                            toggleDrawer={toggleDrawer}
                            genericId={selectedRow.genericId}
                        />
                    </Drawer>
                )}
            </>
        );
    else
        return (
            <>
                <DocuChainList
                    documentChainList={documentChainList}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setShowDocumentChain={setShowDocumentChain}
                    setEditGeneric={setEditGeneric}
                    sortData={sortData}
                ></DocuChainList>
                <Loader show={showLoader}></Loader>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                    setOpen={changeOpenDialogFlag}
                ></AlertDialog>
            </>
        );
};
